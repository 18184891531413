import Vue from 'vue';
import Vuex from 'vuex';
// Types
import globalTypes from '@/store/types/global';
// Modules
import reservationModule from '@/store/modules/reservations';
import whiteLabelModule from '@/store/modules/whiteLabel';
import pollModule from '@/store/modules/poll';
import authModule from '@/store/modules/auth';
import eventModule from '@/store/modules/event';
import sectionModule from '@/store/modules/section';
import userModule from '@/store/modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    online: true,
    url: '',
    vendorUrl: '',
    socket: false,
    isMobile: false,
    orientation: null,
    width: null,
    height: null,
    reservation: (process.browser && window.localStorage.getItem('_reservation') ? JSON.parse(atob(window.localStorage.getItem('_reservation'))) : {}),
    serverTime: null,
    verifyingPayment: false,
    versionApp: 'v5.14.85',
    languageApp: 'es',
    embedSystem: false
  },
  actions: {
    [globalTypes.actions.getServerTime]: ({ commit }, info) => {
      return new Promise((resolve, reject) => {
        const query = {
          timezone: (info.data) ? info.data.timezone : 'America/Bogota'
        };
        Vue.axios.post('dashboard/serverTime', query)
          .then(({ data }) => {
            commit(globalTypes.mutations.setServerTime, data.serverTime);
            resolve(data);
          }).catch(error => {
            reject(error);
          });
      });
    }
  },
  getters: {
    [globalTypes.getters.embedSystem]: state => state.embedSystem,
    [globalTypes.getters.online]: state => state.online,
    [globalTypes.getters.url]: state => state.url,
    [globalTypes.getters.vendorUrl]: state => state.vendorUrl,
    [globalTypes.getters.socket]: state => state.socket,
    [globalTypes.getters.isMobile]: state => state.isMobile,
    [globalTypes.getters.orientation]: state => state.orientation,
    [globalTypes.getters.width]: state => state.width,
    [globalTypes.getters.height]: state => state.height,
    [globalTypes.getters.reservation]: state => state.reservation,
    [globalTypes.getters.serverTime]: state => state.serverTime,
    [globalTypes.getters.versionApp]: state => state.versionApp,
    [globalTypes.getters.verifyingPayment]: state => state.verifyingPayment,
    [globalTypes.getters.languageApp]: state => state.languageApp
  },
  mutations: {
    [globalTypes.mutations.setEmbedSystem] (state, embedSystem) {
      state.embedSystem = embedSystem;
    },
    [globalTypes.mutations.setVendorUrl] (state, url) {
      state.vendorUrl = url;
    },
    [globalTypes.mutations.setOnline] (state, status) {
      state.online = status;
    },
    [globalTypes.mutations.setReservation] (state) {
      state.reservation = (process.browser && window.localStorage.getItem('_reservation')) ? JSON.parse(atob(window.localStorage.getItem('_reservation'))) : {};
    },
    [globalTypes.mutations.setSocket] (state, socket) {
      state.socket = socket;
    },
    [globalTypes.mutations.setWidth] (state, width) {
      state.width = width;
    },
    [globalTypes.mutations.setHeight] (state, height) {
      state.height = height;
    },
    [globalTypes.mutations.setUrl] (state, url) {
      state.url = url;
    },
    [globalTypes.mutations.setServerTime] (state, serverTime) {
      state.serverTime = serverTime;
    },
    [globalTypes.mutations.setVersionApp] (state, versionApp) {
      state.versionApp = versionApp;
    },
    [globalTypes.mutations.setVerifyingPayment] (state, verifyingPayment) {
      state.verifyingPayment = verifyingPayment;
    },
    [globalTypes.mutations.setLanguageApp] (state, languageApp) {
      state.languageApp = languageApp;
    }
  },
  modules: {
    authModule,
    reservationModule,
    whiteLabelModule,
    pollModule,
    eventModule,
    sectionModule,
    userModule
  }
});
